import './App.css';

function App() {
  return (
    <div className='container'>
      <div className='left'>
        <header>
          <img className='logo' src={process.env.PUBLIC_URL + '/banner.png'} alt="logo" />
          <ul className='nav'>
            <li>
              <a className="hover-tufte-underline tufte-underline" target='_blank' href="https://www.linkedin.com/in/narveer-rathore/">linkedin</a>
            </li>
            <li>
              <a className="hover-tufte-underline tufte-underline" target='_blank' href="https://github.com/narveer-rathore">github</a>
            </li>
            <li>
              <a className="hover-tufte-underline tufte-underline" target='_blank' href="https://blog.narveer.dev/">blog</a>
            </li>
            <li>
              <a className="hover-tufte-underline tufte-underline" href="/narveer-rathore-frontend-8-yoe-resume.pdf" >resume</a>
            </li>
            {/* <li>
              <a className="hover-tufte-underline tufte-underline" href="https://narveer.devprojects">projects</a>
            </li> */}
          </ul>
        </header>
        <section className='about-me'>
          <h2 class="d-mob-none"><b>about me</b></h2>
          <div className='meta'>
            <img class="avatar" src={process.env.PUBLIC_URL + '/image.jpg'} alt="picture of narveer rathore" />
            <h1>
              <span class="emp">Hi,</span><br />I am Narveer Rathore 👋<br />
              Building cloud game streaming at <a href="https://now.gg" target="_blank" rel="noreferrer"><span class="emp">now.gg</span></a><br /> and I believe that boredom can do <br /><span class="emp">wonders</span>.
            </h1>
          </div>
        </section>
      </div>
      <div className='right'>
        <h2 className='border-default'>Projects</h2>
        <ul className='list'>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://chromewebstore.google.com/detail/iframe-helper/jkcelhibcnfloeebkhddcdeaoldgojpi">
              <h3>Iframe Helper (2024)</h3>
              <p>Seamlessly list and edit iframe URLs on websites.
                <em className='problem'>Allow quick discovery of H5 games that be onboarded on now.gg</em>
              </p>
              <div className='tags'>
                <label>chrome-extension</label>
              </div>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.whatcolor.cosmicsapne">
              <h3>WhatColor (2021)</h3>
              <p>Android app to help people struggling with color blindness in extracting color codes and widely accepted color names.
                <em className='problem'>My designer friend was having trouble with colors and finding codes</em>
              </p>
              <div className='tags'>
                <label>android</label>
                <label>react-native</label>
              </div>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://chromewebstore.google.com/detail/designmode-page-editor/cpmnkekhlllmhimnigmccneobinciogp?authuser=0&hl=en">
              <h3>DesignMode (2021)</h3>
              <p>Allows anyone to edit any Website by double clicking over text.
                <em className='problem'>Enable designers and managers to experiment with textual changes on web</em>
              </p>
              <div className='tags'>
                <label>chrome-extension</label>
              </div>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://github.com/narveer-rathore/csv2yaml/blob/master/csv2yaml.py">
              <h3>CSV to YAML (2017)</h3>
              <p>Utility Python script to convert CSV to YAML. <br />
                <em className='problem'>Originally created to convert Google Sheet data to Django migrations</em></p>
              <div className='tags'>
                <label>csv</label>
                <label>yaml</label>
                <label>django</label>
              </div>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://github.com/narveer-rathore/FileOrganizer">
              <h3>FileOrganizer (2016)</h3>
              <p>Utility JAVA class to re-organize files by types provided a root folder <br />
                <em className='problem'>Files on desktop were getting out of control (~1000 files)</em></p>
              <div className='tags'>
                <label>csv</label>
                <label>yaml</label>
                <label>django</label>
              </div>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://github.com/narveer-rathore/boom">
              <h3>Boom VST (2016)</h3>
              <p>Audio Generator Plugin (VST) for Digital Audio Workstation (Ableton). Can generate Sine, Square, Triangle waves and White noises with filter.<br />
                <em className='problem'>Learning Digital Audio Synthesis</em></p>
              <div className='tags'>
                <label>audio</label>
                <label>vst</label>
                <label>c++</label>
              </div>
            </a>
          </li>
        </ul>
        <h2 className='border-default'>Certification / Courses</h2>
        <ul className='list'>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://www.coursera.org/account/accomplishments/professional-cert/SLK2E9HUFWVB">
              <h3>IBM Full Stack Software Developer (2024)</h3>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://certificates.cs50.io/577bcece-32de-4257-b69c-4d552b6a4214.pdf?size=letter">
              <h3>Harvard CS50x (2023)</h3>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://s3.amazonaws.com/verify.edx.org/downloads/c30b018ec0a9483582cb11feceed5548/Certificate.pdf">
              <h3>6.00.1x: Introduction to Computer Science and Programming Using Python (2015)</h3>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://s3.amazonaws.com/verify.edx.org/downloads/9bd381e48d694635ac82f4d886bdee46/Certificate.pdf">
              <h3>COMP102.1x: Introduction to Java Programming - Part 1 (2015)</h3>
            </a>
          </li>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://s3.amazonaws.com/verify.edx.org/downloads/65133fe48b5c49f0a6bc1b39f9a9bc64/Certificate.pdf">
              <h3>LFS101x: Introduction to Linux (2014)</h3>
            </a>
          </li>
        </ul>
        <h2 className='border-default'>Workshops</h2>
        <ul className='list'>
          <li className='border-default'>
            <a rel="noreferrer" target="_blank" href="https://www.coursera.org/account/accomplishments/certificate/5CH8HD3WBAXP">
              <h3>Tesla Stock Price Prediction using Facebook Prophet</h3>
            </a>
          </li>
        </ul>
        {/* <footer>copy right </footer> */}
      </div>
    </div>
  );
}

export default App;
